import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMoment from 'vue-moment'
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/display.css'
import './scss/index.scss'
import VueScrollTo from 'vue-scrollto'
import VueMask from 'v-mask'
import VueMobileDetection from "vue-mobile-detection";

import {
  Menu,
  MenuItem,
  Dialog,
  Row,
  Col,
  Card,
  Form,
  FormItem,
  Input,
  InputNumber,
  Tag,
  Button,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
  Steps,
  Step,
  Alert,
  Loading,
  MessageBox,
  Upload,
  Tooltip,
  Popover,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Message,
  DatePicker


} from 'element-ui'
Vue.use(VueScrollTo)

const moment = require('moment')
require('moment/locale/es')
Vue.config.productionTip = false
Vue.use(VueMoment, {
  moment,
})
Vue.use(VueMobileDetection);
Vue.use(DatePicker);
Vue.use(VueMask)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Tabs)
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TabPane)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Alert)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
// Vue.use(Message);


Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
