<template>
  <div>
    <h6 class="pt-sans-bold footer-me">{{ version }}
    </h6>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VUE_APP_VERSION
    };
  }
};
</script>

<style lang="scss" scoped>
.footer-me{
  position: absolute;
  top:97%;
  right: 30px;
  color: rgba(255,255,255,0.5);
  font-size: 12px;
}
</style>