import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = 
[
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home,
  // },
  {
    path: '/',
    name: 'capacitaciones',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/conOfertas.vue'),

  },
  {
    path: '/capacitaciones',
    name: 'listado',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/listado.vue'),

  },
  {
    path: '/capacitaciones/:nivel',
    name: 'capanivel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/listadoNivel.vue'),

  },
  {
    path: '/capacitaciones/:token/:nombre',
    name: 'detalleCapa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/detalleCapa.vue'),

  },
  {
    path: '/deberes-y-derechos-fdci',
    name: 'normativa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/normativa.vue'),

  },
  {
    path: '/normativa1',
    name: 'normativa1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/normativa-admin.vue'),

  },
  {
    path: '/docentes',
    name: 'docentes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Docentes.vue'),

  },
  {
    path: '/docentes/recuperar',
    name: 'confirmarDocente',
    component: () =>
      import(
          /* webpackChunkName: "about" */ '@/views/docentes/recuperar.vue'
      ),

  },
  {
    path: '/docentes/confirmar',
    name: 'confirmarDocente',
    component: () =>
      import(
          /* webpackChunkName: "about" */ '@/views/docentes/confirmar.vue'
      ),

  },
  {
    path: '/docentes/capacitaciones',
    name: 'homeDocente',
    component: () =>
      import(
          /* webpackChunkName: h4c9k3l2v8j7f6g5d4b3a2"about" */ '@/views/docentes/home.vue'
      ),
    meta: { requiresAuth: true },
  },


  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/sinOfertas.vue'),
  },
]
if (process.env.VUE_APP_ENV === 'development') {
  routes.push(
    {
      path: '/h4c9k3l2v8j7f6g5d4b3a2',
      name: 'h4c9k3l2v8j7f6g5d4b3a2',
      component: () =>
        import(
          /* webpackChunkName: h4c9k3l2v8j7f6g5d4b3a2"about" */ '@/views/h4c9k3l2v8j7f6g5d4b3a2.vue'
        ),
      
    }
  );
  
}
const router = new VueRouter({

  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  console.log(from)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const auth = JSON.parse(window.sessionStorage.getItem('userData'))
    console.log(auth)
    if (!auth) {
      next({
        path: '/docentes',
        query: { origen: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
