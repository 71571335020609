<template>
  <div class="header">
    <div class="container">
      <img @click="$router.push('/')" src="@/assets/logo-fdci.png" alt="" style="cursor:pointer">
      <!-- <div style="float:right">
        <img src="@/assets/icons/docente.svg" alt="">
        Juan Alderete
      </div> -->

      <el-popover placement="top-start" title="" width="200" trigger="hover"
        content="Acceda a su Espacio de Formación Personal">

        <avatar slot="reference" fullname="ESPACIO F PERSONAL" @click.native="acceder"
          style="float:right; cursor:pointer;margin-top:0px" :size="55"></avatar>
      </el-popover>




      <img src="@/assets/superior.png" v-if="!$isMobile()" @click="open"
        style="float:right; margin-right:30px; cursor:pointer" alt="">

    </div>
    <nav class="main-menu" v-if="!$isMobile()">
      <div class="container">
        <ul>
          <li><router-link to="/">FDCI</router-link></li>
          <li><a href="https://des-tuc.infd.edu.ar/sitio/" target="_blank">Dirección de Nivel Superior</a></li>
          <li><a href="https://aulas.educaciontuc.gov.ar/" target="_blank">Aulas Virtuales</a></li>
          <li><router-link to="/docentes">Espacio de formación personal
            </router-link></li>
          <li><router-link to="/deberes-y-derechos-fdci">Normativa</router-link></li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import Avatar from 'vue-avatar-component'
import { EventBus } from "@/event-bus"
  export default { 
  components: { Avatar },
  data() {
    return {
      userData: window.sessionStorage.getItem('docente'),
      userDataCuil: window.localStorage.getItem('userDataCuil'),
      userDataPass: window.localStorage.getItem('userDataPass'),



    }
  },
    methods: {
      acceder()
      {
        if (this.$route.name !== 'docentes')
        {
          if (this.userData) {
            if (this.userDataCuil && this.userDataPass) {
              const loginData = {
                cuil: this.userDataCuil,
                pass: this.userDataPass
              }
              EventBus.$emit('loguear', loginData)
              this.$router.push({ name: 'docentes' })
            }
            else {
              this.$router.push({ name: 'docentes' })
            }
          }
          else {
            this.$router.push({ name: 'docentes' })
          }
        }
      
      },
      open() {
        window.open('https://des-tuc.infd.edu.ar/sitio/', '_blank')
      }
    },
  }
</script>

<style lang="scss" scoped>
.header{
  padding: 0px;
  padding-top: 10px;
}
.header img{
 height:60px
}
.main-menu {
  background:#0b395d;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    padding: 6px;
    

    li {
      color:white;
      display: inline;
      margin-right: 20px;
     

      a {
        text-decoration: none;
        color: #FFF; // Example link color
        font-size: 14px;
        
      }
    }
  }
}

// Media query for smaller screens
@media (max-width: 768px) {
  .main-menu {
    display: none; // Hide the menu on small screens
  }

  .container {
    flex-direction: column; // Stack items vertically on small screens
  }
}
</style>